<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="carousel">
          <v-carousel
            :show-arrows="false"
            height="300"
            width="300"
            cycle
            interval="4000"
          >
            <v-carousel-item
              ripple=""
              v-for="(item, i) in items"
              :key="i"
              transition="v-fade-transition"
            >
              <img :src="item.src" alt="" style="height:250px; width:100%" />
            </v-carousel-item>
          </v-carousel>
          <v-btn
            width="300"
            style="background:#28A84A; color:white; padding:1.5rem"
            class="mt-6"
            @click="goMote()"
            >BUY COLD BOX</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="right_">
        <p class="subtitle-2" style="color:#28A84A">
          Gricd Cold Box
        </p>
        <h1 style="font-size:1.8rem" class="mb-6">Mote 50</h1>
        <p>
          The Gricd Cold Box (mobile refrigerator)is a reusable all-in-one
          storage tracker for cold chain environmental products monitoring.
          These devices are portable, thus can be carried easily as a backpack,
          also on a bicycle, or motorcycle. With Gricd Cold Box, you can monitor
          your valuable pharma and agricultural assets in real-time and be
          alerted to any environmental changes. By adding real-time monitoring
          capabilities, actionable data is generated immediately a temperature
          excursion is detected so designated persons at each level of the cold
          chain can take proactive decisions that reduce damages or loss of
          integrity.
        </p>
      </v-col>
    </v-row>
    <div class="feature" :class="$vuetify.breakpoint.xs ? 'pa-5' : 'pa-12'">
      <h3>Features</h3>
      <v-row class="py-6">
        <v-col cols="12" sm="6">
          <div class="text-content-container ">
            <h4 class="heading">
              Temperature
            </h4>
            <p class="text-content">
              Temperature range between +25° C to -20° C.
            </p>
          </div>
          <div class="text-content-container ">
            <h4 class="heading">
              Lasting Battery
            </h4>
            <p class="text-content">
              Where reliable power is a challenge, MOTE50 have a run down time
              of up to 24 hours without recharge.
            </p>
          </div>
          <div class="text-content-container ">
            <h4 class="heading">
              Remote Monitoring & Control
            </h4>
            <p class="text-content">
              Powered with Internet of Things, MOTE50 can be connected to a
              mobile device and and monitored remotely.
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="text-content-container ">
            <h4 class="heading">
              Internal Capacity volume
            </h4>
            <p class="text-content">
              50 liters
            </p>
          </div>
          <div class="text-content-container ">
            <h4 class="heading">
              Temperature Control
            </h4>
            <p class="text-content">
              Gricd boxes are designed to maintain critical temperature values
              throughout their use.
            </p>
          </div>
          <div class="text-content-container ">
            <h4 class="heading">
              Alarm Triggers
            </h4>
            <p class="text-content">
              In the case of emergencies, an alarm is triggered in real-time
              configurable via SMS, Email and push notifications.
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      class="last d-block"
      :class="$vuetify.breakpoint.xs ? 'pa-2 pt-12' : 'pa-12'"
    >
      <h2>
        Other Device Specification
      </h2>
      <div class="tab mt-8">
        <v-tabs background-color="#28A84A" dark v-model="tab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab v-for="item in itemsTab" :key="item" dark>
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in itemsTab" :key="item" transition="css">
            <div class="mt-8">
              <Table1 v-show="item === 'Key Features'" />
              <Table2 v-show="item === 'Specifications'" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import one from '../../assets/images/image (2).png';
import two from '../../assets/images/Rectangle 1189.png';
import three from '../../assets/images/image (2).png';
import Table1 from '../../components/TableType';
import Table2 from '../../components/TableType2';

export default {
  name: 'GricdMote',
  components: {Table1, Table2},
  data: () => ({
    tab: null,
    itemsTab: ['Key Features', 'Specifications'],
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    items: [
      {
        src: one,
      },
      {
        src: two,
      },
      {
        src: three,
      },
    ],
  }),
  methods: {
    goMote: () => {
      location.href = 'https://buy.gricd.com/product/mote50/';
    },
  },

  metaInfo: {
    title: 'Mote 50',
    // override the parent template and just use the above title only
    titleTemplate: '%s - Cold Chain Equipments - Gricd.com',
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: 'https://gricd.com/',
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: 'Cold Chain Equipments',
              item: 'https://gricd.com/cold-chain-equipments',
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: 'Mote 50',
            },
          ],
        },
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  justify-content: center;
  padding: 4rem 0;
}
.v-carousel {
  height: 300px;
  width: 300px;
}
.right_ {
  justify-content: center;
  display: flex;
  flex-flow: column;
  padding: 3rem;
  @media (max-width: 767px) {
    padding: 2rem;
  }
}
.text-content-container {
  min-height: 6rem;
  //   border: solid;
  padding-bottom: 10px;
  padding-right: 5rem;
  h4 {
    padding-bottom: 20px;
    color: #c69d6d;
  }
}
h3 {
  //   padding-left: 13px;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 180%;
  color: #212529;
}
.feature {
  background: rgba(230, 236, 238, 0.46);
}
.v-tab.v-tab {
  //   border: solid 1px black;
  //   border-bottom: 0;
  margin: 0 15px;
  @media (max-width: 767px) {
    margin: 0 5px;
  }
}
.carousel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
</style>
