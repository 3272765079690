<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Model</span>: FRIJ50 / FRIJI5</td>
        </tr>
        <tr>
          <td><span>Outer Dimension:</span> (59CM x 55CM x 38CM)</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td>
            <span>Inner Dimension:</span> (32CM x 45CM x 25CM, 16CM x 30CM x
            25CM)
          </td>
        </tr>
        <tr>
          <td><span>Power:</span> 12/24V DC or 240V AC.</td>
        </tr>
        <tr  style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Battery Runtime:</span> Up to 24 hours</td>
        </tr>
        <tr >
          <td><span>Net weight:</span> 5.5kg</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'Table1',
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
}
td {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  /* Text */

  color: #212529;
}
</style>
